/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { History } from 'history';
import { injectable, inject } from 'inversify';
import Cookies from 'js-cookie';
import { makeObservable, observable, action } from 'mobx';
import { MIGRATED_CHARACTER_NAME, NEWCOMER_TARGET_ROUTE_STORAGE_KEY, CookieName } from '@common/constants';
import { GenericObject } from '@common/types';
import { fromISO639 } from '@i18n/utils';
import { Loader, Snackbars, Localisation, User, AccountMigration, GameAccounts } from '@stores/domains';
import { MigrationCandidateView, EligibleMigrationCandidate } from '@stores/domains/AccountMigration/types';
import { withAuth } from '@stores/domains/Auth/decorators';
import { Requests } from '@stores/domains/GameAccounts/Requests';
import { loadable } from '@stores/domains/Loader/decorators';
import { LoaderState } from '@stores/domains/Loader/types';
import { NewcomerActionVariant } from '@stores/domains/User/types';
import { TYPES } from '@stores/types';
import { LoaderName } from '@stores/views/NewcomersPage/constants';
import { noop, executeWhenNotAuthError } from '@utils/helpers';
import { getLastTargetRoute } from '@utils/navigation';

@injectable()
export class NewcomersPage {
  @inject(TYPES.AccountMigration) accountMigration: AccountMigration;
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Localisation) localisation: Localisation;
  @inject(TYPES.Snackbars) snackbars: Snackbars;
  @inject(TYPES.User) user: User;
  @inject(TYPES.GameAccounts) gameAccounts: GameAccounts;

  characterName: string;
  hasError = false;
  newcomerActionVariant: NewcomerActionVariant;
  targetRoute = '';

  constructor() {
    makeObservable(this, {
      characterName: observable,
      hasError: observable,
      newcomerActionVariant: observable,
      targetRoute: observable,
      setCharacterName: action,
      setHasError: action,
      setNewcomerActionVariant: action,
      setTargetRoute: action
    });
  }

  setCharacterName(characterName: string): void {
    this.characterName = characterName;
  }

  setHasError(hasError: boolean): void {
    this.hasError = hasError;
  }

  setNewcomerActionVariant(newcomerActionVariant: NewcomerActionVariant): void {
    this.newcomerActionVariant = newcomerActionVariant;
  }

  setTargetRoute(targetedRoute: string): void {
    this.targetRoute = targetedRoute;
  }

  @loadable(LoaderName.PageInit)
  async init(params: GenericObject, history: History): Promise<void> {
    const { lang } = params;
    const lastTargetRoute = getLastTargetRoute(NEWCOMER_TARGET_ROUTE_STORAGE_KEY);

    if (lastTargetRoute) {
      try {
        this.setTargetRoute(lastTargetRoute);

        const newcomerAction = await this.user.getNewcomerAction();

        switch (newcomerAction.type) {
          case NewcomerActionVariant.Skip: {
            await this.handleDefault(lastTargetRoute);
            break;
          }
          case NewcomerActionVariant.Import: {
            await this.handleAutoImport(newcomerAction.eligibleCandidate);
            break;
          }
          case NewcomerActionVariant.Create: {
            await this.handleNew(lang);
            break;
          }
          default: {
            this.setHasError(true);
          }
        }
      } catch (e) {
        await executeWhenNotAuthError(e, async () => {
          this.setHasError(true);
        });
      }
    } else {
      this.setHasError(true);
    }
  }

  async handleAutoImport(eligibleCandidate: EligibleMigrationCandidate): Promise<void> {
    if (eligibleCandidate.characterId) {
      await this.accountMigration.migrateEligibleCandidate(eligibleCandidate.characterId);
      const characterName = sessionStorage.getItem(MIGRATED_CHARACTER_NAME) as string;

      this.setCharacterName(characterName);
      this.setNewcomerActionVariant(NewcomerActionVariant.Import);

      await this.accountMigration.getEligibleCandidates(MigrationCandidateView.Hidden).catch(noop);
    } else {
      this.setHasError(true);
    }
  }

  @withAuth
  async handleNew(lang: string): Promise<void> {
    await Requests.createAccount({
      clientLanguageCode: fromISO639(lang),
      receiveEmails: this.user.isReceiveMarketingEmailsConsented,
      thirdPartyConsent: this.user.isThirdPartyConsented
    });
    this.setNewcomerActionVariant(NewcomerActionVariant.Create);

    await this.accountMigration.getEligibleCandidates(MigrationCandidateView.Hidden).catch(noop);
  }

  async handleDefault(redirectUrl: string): Promise<void> {
    const existingMigratedCharacterName = sessionStorage.getItem(MIGRATED_CHARACTER_NAME);

    if (existingMigratedCharacterName) {
      this.setCharacterName(existingMigratedCharacterName);
      this.setNewcomerActionVariant(NewcomerActionVariant.Import);

      await this.accountMigration.getEligibleCandidates(MigrationCandidateView.Hidden).catch(noop);
    } else {
      window.location.assign(redirectUrl);
    }
  }

  handleFinish(): void {
    this.loader.setLoaderState(LoaderName.HandleFinish, LoaderState.Pending);
    this.gameAccounts.setSkipCharactersCheck(true);

    sessionStorage.removeItem(MIGRATED_CHARACTER_NAME);
    sessionStorage.removeItem(NEWCOMER_TARGET_ROUTE_STORAGE_KEY);
    Cookies.remove(CookieName.SkipCookieDeclaration);

    window.location.assign(this.targetRoute);
  }

  onLeave(): void {
    this.setTargetRoute('');
  }
}
