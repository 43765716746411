/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Box, Flex, Loader } from '@jagex/design_jagex';
import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderState } from '@stores/domains/Loader/types';
import { CheckIcon, CrossIcon } from './styled';

type Props = {
  state?: LoaderState;
};

// Since Loader component doesn't allow to pass data-testid props, we need a wrapper to do it.
// TODO: allow all primitives to receive data-testid
export const PageLoader: FunctionComponent<Props> = ({ state }) => {
  const { t } = useTranslation();

  const getLoaderState = (passedState?: LoaderState): ReactNode => {
    switch (passedState) {
      case LoaderState.Pending:
        return (
          <Box data-testid="pending-circle-spinner-icon">
            <Loader aria-label={t('general:loading:inProgress')} />
          </Box>
        );
      case LoaderState.Finished:
        return <CheckIcon color="green" data-testid="success-check-icon" aria-label="Successful loading" />;
      case LoaderState.Failed:
        return <CrossIcon color="red" data-testid="failed-cross-icon" aria-label="Failed loading" />;
      default:
        return (
          <Box data-testid="pending-initial-circle-spinner-icon">
            <Loader aria-label="Loading started" />
          </Box>
        );
    }
  };

  return <Flex data-testid="loader-container">{getLoaderState(state)}</Flex>;
};
