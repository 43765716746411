/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosInstance } from 'axios';
import i18n, { i18n as i18nextType } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { HEADER_LOCALE } from '@utils/constants';
import { I18nFacade } from './I18nFacade';

export class I18n {
  api: AxiosInstance;
  i18nInstance: i18nextType;
  private locale: string;
  private localeHeaderInterceptor: number;

  constructor(api: AxiosInstance) {
    this.i18nInstance = i18n.use(initReactI18next);
    this.api = api;
  }

  detectUserLocale(): void {
    this.locale = I18nFacade.detectUserLocale();
  }

  async getTranslations(): Promise<void> {
    const cfg = await I18nFacade.getTranslations(this.api, this.locale);
    await this.i18nInstance.init(cfg);
    this.setAPILocaleHeader();
  }

  getSelectedLanguage(): string {
    return this.i18nInstance.language;
  }

  saveCurrentLocale(): void {
    I18nFacade.saveCurrentLocale(this.i18nInstance.language);
  }

  setAPILocaleHeader(): void {
    if (this.localeHeaderInterceptor) {
      this.api.interceptors.request.eject(this.localeHeaderInterceptor);
    }

    this.localeHeaderInterceptor = this.api.interceptors.request.use(
      config => {
        config.headers[HEADER_LOCALE] = this.getSelectedLanguage();
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
}
