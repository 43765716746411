/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Loader } from '@jagex/design_jagex';
import { Loader as NewLoader } from '@jagex/jds';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ContentWrapper } from '@components/layouts';

type Props = {
  loading?: boolean;
};

export const LoadingBox: FunctionComponent<PropsWithChildren<Props>> = ({ loading = true, children, ...props }) => {
  return loading ? (
    <ContentWrapper data-testid="loading-box" {...props}>
      <Loader />
    </ContentWrapper>
  ) : (
    <>{children}</>
  );
};

export const NewLoadingBox: FunctionComponent<PropsWithChildren<Props>> = ({ loading = true, children }) => {
  return loading ? <NewLoader style={{ margin: '0 auto' }} /> : <>{children}</>;
};
