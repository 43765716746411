/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { ThemeProvider } from '@jagex/design_jagex';
import React, { FunctionComponent } from 'react';
import { Store } from '@stores/createStore';
import { StoreProvider } from '@stores/Provider';
import { AppRouter } from './AppRouter';
import { I18n } from './i18n';

interface Props {
  i18nInstance: I18n;
  store: Store;
}

const App: FunctionComponent<Props> = ({ i18nInstance, store }) => (
  <StoreProvider store={store}>
    <ThemeProvider>
      <AppRouter i18nInstance={i18nInstance} />
    </ThemeProvider>
  </StoreProvider>
);

export default App;
