/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { ParsedQuery } from 'query-string';
import { api, extractResponse } from '@utils/network';
import { LoginInfo, LangData, LogoutResponse, RegistrationInfo, RegistrationStartRequestData } from './types';

export const Requests = {
  checkAuthStatus: (config?: AxiosRequestConfig): Promise<void> => api.get(`/sessions`, config),
  login: (options: AxiosRequestConfig = {}): Promise<LoginInfo> => {
    return extractResponse<LoginInfo>(api.get('/oauth/login', options));
  },
  startRegistration: (
    body: RegistrationStartRequestData,
    options: AxiosRequestConfig = {}
  ): Promise<RegistrationInfo> => {
    return extractResponse<RegistrationInfo>(api.post('/oauth/registration/regular', body, options));
  },
  getAuthTokens: (body: ParsedQuery, options: AxiosRequestConfig = {}): Promise<void> => {
    return api.post('/oauth/token', body, options);
  },
  logout: (body: LangData, options: AxiosRequestConfig = {}): Promise<LogoutResponse> => {
    return extractResponse<LogoutResponse>(api.post('/oauth/logout', body, options));
  }
};
