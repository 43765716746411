/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { makeObservable, observable, action, computed } from 'mobx';
import { DEFAULT_LOCALE_KEY } from '@i18n/config';
import { I18n } from '@i18n/i18n';
import { addLocaleToUrl } from '@stores/domains/Localisation/utils';

@injectable()
export class Localisation {
  localisationInstance: I18n | null = null;

  constructor() {
    makeObservable(this, {
      localisationInstance: observable,
      setLocalisationInstance: action,
      locale: computed
    });
  }

  setLocalisationInstance(localisationInstance: I18n): void {
    this.localisationInstance = localisationInstance;
  }

  get locale(): string {
    return this.localisationInstance?.i18nInstance.language || DEFAULT_LOCALE_KEY;
  }

  addLocaleToUrl(url: string): string {
    return addLocaleToUrl(url, this.locale);
  }
}
