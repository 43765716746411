/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import parse from 'url-parse';
import { DEFAULT_LOCALE_KEY } from '@i18n/config';
import { matchesLocaleFormat } from '@i18n/utils';

export const addLocaleToUrl = (url: string, locale = DEFAULT_LOCALE_KEY): string => {
  const { origin, pathname, query } = parse(url);

  if (origin && origin !== 'null') {
    // Origin is presented and already includes lang then it should be absolute url without provided locale
    if (matchesLocaleFormat(pathname)) {
      return `${origin}${pathname}${query}`;
    }

    // Origin is presented then it should be absolute url, like direct url with provided locale
    return `${origin}/${locale}${pathname}${query}`;
  }

  // Origin is not presented it should be a relative path, like /login
  return `/${locale}${pathname}${query}`;
};
