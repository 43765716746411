/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Location } from 'history';
import { TARGET_ROUTE_STORAGE_KEY } from '@common/constants';

export const makeRouteFromLocation = ({ pathname, search, hash }: Location): string => `${pathname}${search}${hash}`;

export const setTargetRoute = (location: Location, key = TARGET_ROUTE_STORAGE_KEY): void => {
  sessionStorage.setItem(key, makeRouteFromLocation(location));
};

export const getLastTargetRoute = (key = TARGET_ROUTE_STORAGE_KEY): string | null => sessionStorage.getItem(key);
