/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import 'reflect-metadata';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from '@stores/createStore';
import { APP_ROOT_ID } from '@utils/constants';
import { api } from '@utils/network';
import App from './App';
import { I18n } from './i18n';

const i18n = new I18n(api);
const store = createStore();

const container = document.getElementById(APP_ROOT_ID);
const root = createRoot(container!);
root.render(<App i18nInstance={i18n} store={store} />);
