/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Routes } from '@common/routes';
import { StatusCode } from '@utils/constants';
import { extractAxiosErrorResponse, extractErrorMessage, extractErrorStatus } from '@utils/network';

/**
 * Decorator that works as a middleware for requests and shows a toast with set message,
 * Must be used in sub-stores since needs access to the rootStore property.
 * @param successMessage
 * @param errorMessage
 */
export function notify<T>(successMessage?: string | null, errorMessage?: string | null) {
  return function(target: T, propertyKey: string, descriptor: PropertyDescriptor): void {
    const originalFn = descriptor.value!;
    descriptor.value = async function(...args) {
      if (!this.snackbars && process.env.NODE_ENV === 'development') {
        console.error('Developer Error: notify decorator needs to have snackbars store injected');
        return;
      }

      try {
        const result = await originalFn.call(this, ...args);

        if (successMessage) {
          this.snackbars.enqueueDefaultSuccess(successMessage);
        }

        return result;
      } catch (e) {
        const extractedError = extractAxiosErrorResponse(e);
        const errMessage = extractErrorMessage(extractedError, errorMessage);
        const errStatus = extractErrorStatus(extractedError);

        if (!errMessage || errStatus >= StatusCode.InternalServerError) {
          window.location.assign(Routes.Error.route);
        } else {
          this.snackbars.enqueueDefaultError(errMessage);

          return Promise.reject(e);
        }
      }
    };
  };
}
