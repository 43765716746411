/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { flow } from 'mobx';
import { LoaderState } from './types';

/**
 * loader decorator. Tracks current state of a request/async action. Uses the loading store.
 * Must be used within a sub-store.
 *
 * @param loaderName - loader's unique name
 */
export function loadable<T>(loaderName: string, finaliseLoading = true) {
  return function(target: T, propertyKey: string, descriptor: PropertyDescriptor): void {
    const originalFn = descriptor.value!;
    descriptor.value = flow(function*(...args) {
      if (!this.loader) {
        // TODO: Refactor - create Error
        console.error('Developer Error: loadable decorator needs to have Loader store injected');
        return;
      }

      this.loader.setLoaderState(loaderName, LoaderState.Pending);
      try {
        const result = yield originalFn.call(this, ...args);

        if (finaliseLoading) {
          this.loader.setLoaderState(loaderName, LoaderState.Finished);
        }

        return result;
      } catch (e) {
        this.loader.setLoaderState(loaderName, LoaderState.Failed);

        return Promise.reject(e);
      }
    });
  };
}
