/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { InitOptions } from 'i18next';

export const DEFAULT_LOCALE_KEY = 'en-GB';
export const DEFAULT_NS = 'app';

export const baseConfig: InitOptions = {
  debug: process.env.NODE_ENV === 'development',
  defaultNS: DEFAULT_NS,
  keySeparator: false,
  nsSeparator: '::',
  interpolation: {
    escapeValue: false
  },
  fallbackLng: [DEFAULT_LOCALE_KEY]
};

export enum Language {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  PORTUGUESE = 'pt'
}

export const LOCALE_REGEX = /^\/(([a-zA-Z]{2})-([a-zA-Z]{2}))(?=(\/|$)){1}/;
export const LANGUAGE_REGEX = /^\/([a-zA-Z]{2})(?=(\/|$)){1}/;
