/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { Box, theme } from '@jagex/design_jagex';

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${theme.space[6]}px ${theme.space[7]}px;
  background-color: ${theme.colors.backgroundBlue};
  position: relative;

  @media screen and (max-width: ${theme.breakpoints[1]}) {
    padding: ${theme.space[7]}px ${theme.space[6]}px ${theme.space[6]}px;
  }
`;
