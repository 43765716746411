/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Text, Button } from '@jagex/design_jagex';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { GameAccounts } from '@common/routes/routes';
import { CenteredContainer } from '@components/layouts';
import { DEFAULT_LOCALE_KEY } from '@i18n/config';
import { useStore } from '@stores/useStore';

const NotFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { localisation } = useStore();

  const goBack = () => window.location.assign(GameAccounts.url({ lang: localisation.locale || DEFAULT_LOCALE_KEY }));

  return (
    <CenteredContainer>
      <Text as="h2" sx={{ textAlign: 'center', mb: 2 }}>
        {t('general:notFound:mainMessage')}
      </Text>
      <Button onClick={goBack} csx={{ marginTop: 2 }}>
        {t('general:buttons:home')}
      </Button>
    </CenteredContainer>
  );
};

export default NotFoundPage;
