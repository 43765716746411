/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosInstance } from 'axios';
import { Location } from 'history';
import { DEFAULT_LOCALE_KEY, baseConfig } from './config';
import { Request } from './requests';
import { Options } from './types';
import {
  extractLocaleFromSessionStorage,
  extractLocaleFromURL,
  setLocaleIntoSessionStorage,
  extractLocaleFromQueryParams
} from './utils';

export class I18nFacade {
  static detectUserLocale(): string {
    return extractLocaleFromSessionStorage() ?? extractLocaleFromURL() ?? extractLocaleFromQueryParams() ?? '';
  }

  static async getTranslations(api: AxiosInstance, code?: string): Promise<any> {
    const config: Options = {
      resources: {},
      fallbackLng: []
    };
    const { locale, ...resources } = await I18nFacade.loadSelectedTranslation(api, code);

    config.lng = locale;
    config.resources[locale] = resources;

    if (locale !== DEFAULT_LOCALE_KEY) {
      const resources = await I18nFacade.loadSelectedTranslation(api, DEFAULT_LOCALE_KEY);

      config.fallbackLng.push(DEFAULT_LOCALE_KEY);
      config.resources[DEFAULT_LOCALE_KEY] = resources;
    }

    return { ...baseConfig, ...config };
  }

  static async loadSelectedTranslation(api: AxiosInstance, code?: string): Promise<any> {
    const params = code ? { code } : {};
    return Request.translation(api, { params });
  }

  static updatePathWithCorrectLocale({ pathname, search }: Location, newLocale: string): string | null {
    const locale = extractLocaleFromURL();

    if (locale === newLocale) {
      return null;
    } else {
      const updatedPath = locale ? pathname.replace(/^\/[\w-]{2,5}/, newLocale) : `${newLocale}${pathname}`;

      return `/${updatedPath}${search}`;
    }
  }

  static saveCurrentLocale(locale: string): void {
    setLocaleIntoSessionStorage(locale);
  }
}
