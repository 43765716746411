/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { withHeadersFilter } from '@jagex/configuration/lib/filterHeaders/withHeadersFilter';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { GenericObject, NetworkError, ResponseWithHeaders, HttpResponseNetworkError } from '@common/types';
import { ErrorCodeMessages } from '@utils/translations';
import { ErrorStatusMessages, StatusCode, API_PREFIX } from './constants';
import { addSecurityCheck } from './security';

export const api = withHeadersFilter(
  axios.create({
    baseURL: API_PREFIX,
    withCredentials: true
  })
);

export const extractResponse = async <T>(request: Promise<AxiosResponse<T>>): Promise<T> => {
  const response = await addSecurityCheck(request);

  return response.data;
};

export const extractResponseWithHeaders = async <T>(
  request: Promise<AxiosResponse<T>>
): Promise<ResponseWithHeaders<T>> => {
  const { data, headers } = await addSecurityCheck(request);

  return { data, headers };
};

// Simplifying this check since we need only data usually.
export const isAxiosResponse = (obj: GenericObject): obj is AxiosResponse => Boolean(obj.data);

export const isHttpResponseNetworkError = (error?: NetworkError | any): error is HttpResponseNetworkError =>
  error?.errorType === 'httpResponse';

/**
 * Message extractor. If message exists in response (if response even exists), use it, otherwise fallback to override,
 * and otherwise use the default error message.
 * @param response
 * @param overrideMessage
 */
export const extractErrorMessage = (
  response?: AxiosResponse<NetworkError> | any,
  overrideMessage?: string | null
): string => {
  const fallbackMessage = overrideMessage || ErrorCodeMessages.GeneralError;

  if (!isAxiosResponse(response)) {
    return ErrorCodeMessages[`${fallbackMessage}`] ?? fallbackMessage;
  }

  if (isHttpResponseNetworkError(response.data)) {
    const { data } = response.data;

    return ErrorCodeMessages[`${data.code}`] ?? fallbackMessage;
  }

  return ErrorStatusMessages[`${response.status}`] ?? fallbackMessage;
};

export const isErrorCodeMatch = (response: AxiosResponse<NetworkError> | any, code: string): boolean => {
  if (!isAxiosResponse(response) || isHttpResponseNetworkError(response.data)) {
    const { data } = response.data;
    return data.code === code;
  }

  return false;
};

export const extractAxiosErrorResponse = (error: AxiosError | any): AxiosResponse | any => error?.response ?? error;

/**
 * Status extractor. If status exists in response (if response even exists), use it, otherwise InternalServerError (500)
 * @param response
 */
export const extractErrorStatus = (response?: AxiosResponse<NetworkError> | any): number => {
  if (!isAxiosResponse(response)) {
    return response.status;
  }

  if (isHttpResponseNetworkError(response.data)) {
    const { data } = response.data;

    return data.status ?? StatusCode.InternalServerError;
  }

  return response.status ?? StatusCode.InternalServerError;
};

export const checkErrorStatusCode = (
  expectedStatusCode: number | number[],
  response?: AxiosResponse<NetworkError> | any
): boolean => {
  const statusCode = extractErrorStatus(extractAxiosErrorResponse(response));

  if (Array.isArray(expectedStatusCode)) {
    return expectedStatusCode.includes(statusCode);
  }

  return expectedStatusCode === statusCode;
};

export const deleteCookie = (cookiesName: string[]): void => {
  cookiesName.forEach((cookieName: string) => {
    Cookies.remove(cookieName);
  });
};
