/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps, Route, RouteComponentProps, useLocation, useHistory } from 'react-router-dom';
import { Routes } from '@common/routes';
import { Loading } from '@components/layouts';
import { I18nFacade } from '@i18n/I18nFacade';
import { AuthStatus } from '@stores/domains/Auth/types';
import { UserType } from '@stores/domains/User/types';
import { useStore } from '@stores/useStore';
import { setTargetRoute } from '@utils/navigation';

type Props = Omit<RouteProps, 'component'> & {
  component: FunctionComponent<RouteComponentProps>;
  isJagexAccountRestricted?: boolean;
};

export const ProtectedRoute: FunctionComponent<Props> = observer(
  ({ component: Component, isJagexAccountRestricted = false, ...restProps }) => {
    const { auth, user } = useStore();
    const { authStatus, isAuthenticated } = auth;
    const {
      i18n: { language: lang }
    } = useTranslation();
    const [isUserTypeLoaded, setIsUserTypeLoaded] = useState(false);
    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
      const checkAuth = async () => {
        if (authStatus === AuthStatus.UNKNOWN) {
          await auth.checkAuthStatus();
        }

        if (!auth.isAuthenticated) {
          setTargetRoute(location);

          const { loginLink } = await auth.login(lang);

          I18nFacade.saveCurrentLocale(lang);

          window.location.assign(loginLink);
        }
      };

      checkAuth();
    }, [auth, authStatus, lang, location]);

    useEffect(() => {
      const loadUserType = async () => {
        if (!isAuthenticated) {
          return;
        }

        if (!user.userTypeInformation) {
          await user.getCurrentUserTypeInformation();
        }

        if (isJagexAccountRestricted && user.userTypeInformation?.userType === UserType.Jagex) {
          history.push(Routes.GameAccounts.url({ lang }));

          return;
        }

        if (!isJagexAccountRestricted && user.userTypeInformation?.userType === UserType.FirstParty) {
          history.push(Routes.Upgrade.url({ lang }));

          return;
        }

        setIsUserTypeLoaded(true);
      };

      loadUserType();
    }, [isAuthenticated, user, lang, isJagexAccountRestricted, history]);

    return isAuthenticated && isUserTypeLoaded ? (
      <Route {...restProps} render={props => <Component {...props} />} />
    ) : (
      <Loading />
    );
  }
);
