/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import React, { FunctionComponent } from 'react';
import { Text, TextProps } from 'theme-ui';
import { PAGE_HEADING_ID } from '@utils/constants';

/**
 * This must be a unique per-page component, since we define common page title with a constant `id` attribute.
 */
export const Heading: FunctionComponent<Omit<TextProps, 'ref' | 'id'>> = ({ children, sx = {}, ...props }) => (
  <Text as="h1" variant="text.h3" sx={{ textAlign: 'center', my: 2, ...sx }} {...props} id={PAGE_HEADING_ID}>
    {children}
  </Text>
);
