/*
 * Copyright 1999-2025 Jagex Ltd.
 */
export enum LoaderName {
  GetMigrationData = 'account-migration--get-migration-data',
  StartRegistration = 'account-migration--start-registration',
  FinishMigrationFlow = 'account-migration--finish-migration-flow',
  GetEligibleCandidates = 'account-migration--get-eligible-candidates',
  StartAccountMigration = 'account-migration--start-account-migration',
  UpgradeAccount = 'account-migration--upgrade-account'
}
