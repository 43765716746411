/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { Global, css, SerializedStyles } from '@emotion/core';
import React, { FunctionComponent } from 'react';
import { APP_ROOT_ID } from '@utils/constants';

const GlobalStyle: SerializedStyles = css`
  #${APP_ROOT_ID} {
    position: relative;
  }
`;

export const GlobalStyles: FunctionComponent = () => <Global styles={GlobalStyle} />;
