/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { GameAccountsPage, AccountMigrationPage, NewcomersPage, BeginYourAdventurePage } from '@stores/views';
import { container } from './config';
import { Loader, Snackbars, Localisation, Auth, User, AccountSelect, AccountMigration, GameAccounts } from './domains';
import { TYPES } from './types';

export type Store = {
  // Domains
  loader: Loader;
  localisation: Localisation;
  snackbars: Snackbars;
  auth: Auth;
  user: User;
  accountSelect: AccountSelect;
  accountMigration: AccountMigration;
  gameAccounts: GameAccounts;

  // Views
  gameAccountsPage: GameAccountsPage;
  accountMigrationPage: AccountMigrationPage;
  newcomersPage: NewcomersPage;
  beginYourAdventurePage: BeginYourAdventurePage;
};

export const createStore = (): Store => {
  // Domains
  const loader = container.get<Loader>(TYPES.Loader);
  const localisation = container.get<Localisation>(TYPES.Localisation);
  const snackbars = container.get<Snackbars>(TYPES.Snackbars);
  const auth = container.get<Auth>(TYPES.Auth);
  const user = container.get<User>(TYPES.User);
  const accountSelect = container.get<AccountSelect>(TYPES.AccountSelect);
  const accountMigration = container.get<AccountMigration>(TYPES.AccountMigration);
  const gameAccounts = container.get<GameAccounts>(TYPES.GameAccounts);

  // Views
  const gameAccountsPage = container.get<GameAccountsPage>(TYPES.GameAccountsPage);
  const accountMigrationPage = container.get<AccountMigrationPage>(TYPES.AccountMigrationPage);
  const newcomersPage = container.get<NewcomersPage>(TYPES.NewcomersPage);
  const beginYourAdventurePage = container.get<BeginYourAdventurePage>(TYPES.BeginYourAdventurePage);

  return {
    // Domains
    loader,
    localisation,
    snackbars,
    auth,
    user,
    accountSelect,
    accountMigration,
    gameAccounts,

    // Views
    gameAccountsPage,
    accountMigrationPage,
    newcomersPage,
    beginYourAdventurePage
  };
};
