/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import React, { FunctionComponent, Suspense, lazy } from 'react';
import { lazily } from 'react-lazily';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Routes } from '@common/routes';
import { LocalizationSwitch } from '@common/routes/components';
import { SwitchWithNotFound } from '@components/appNavigation';
import { LoadingBox, UIWrapper } from '@components/common';
import { GeneralHelmet } from '@components/meta';
import { I18n, LocalizationLoader } from './i18n';

const AuthRoutes = lazy(() => import('@views/Auth/AuthRoutes'));
const GameAccountsPage = lazy(() => import('@views/GameAccountsPage/GameAccountsPage'));
const SelectGameAccountPage = lazy(() => import('@views/SelectGameAccountPage/SelectGameAccountPage'));
const AccountMigrationPage = lazy(() => import('@views/AccountMigrationPage/AccountMigrationPage'));
const CommonErrorPage = lazy(() => import('@views/CommonErrorPage/CommonErrorPage'));
const UnableImportPage = lazy(() => import('@views/UnableImportPage/UnableImportPage'));
const NewcomersPage = lazy(() => import('@views/NewcomersPage/NewcomersPage'));
const UpgradePromptPage = lazy(() => import('@views/UpgradePromptPage/UpgradePromptPage'));
const BeginYourAdventurePage = lazy(() => import('@views/BeginYourAdventurePage/BeginYourAdventurePage'));
const HomeRoutes = lazy(() => import('@common/routes/components/HomeRoutes'));

const { ProtectedRoute } = lazily(() => import('@components/appNavigation'));

interface Props {
  i18nInstance: I18n;
}

export const AppRouter: FunctionComponent<Props> = ({ i18nInstance }) => (
  <BrowserRouter>
    <UIWrapper>
      <LocalizationLoader i18n={i18nInstance}>
        <Suspense fallback={<LoadingBox />}>
          <GeneralHelmet />
          <Switch>
            <LocalizationSwitch>
              <SwitchWithNotFound>
                <Route path={Routes.Auth.route} component={AuthRoutes} />
                <Route path={Routes.CommonError.route} component={CommonErrorPage} />
                <Route path={Routes.UnableImport.route} component={UnableImportPage} />
                <Route path={Routes.SelectGameAccount.route} component={SelectGameAccountPage} />
                <Route path={Routes.AccountMigration.route} component={AccountMigrationPage} />
                <Route path={Routes.BeginYourAdventure.route} component={BeginYourAdventurePage} />
                <ProtectedRoute
                  path={Routes.Upgrade.route}
                  component={UpgradePromptPage}
                  isJagexAccountRestricted={true}
                />
                <ProtectedRoute path={Routes.Newcomers.route} component={NewcomersPage} />
                <ProtectedRoute path={Routes.GameAccounts.route} component={GameAccountsPage} />
                <Route path={Routes.Home.route} component={HomeRoutes} />
              </SwitchWithNotFound>
            </LocalizationSwitch>
          </Switch>
        </Suspense>
      </LocalizationLoader>
    </UIWrapper>
  </BrowserRouter>
);
