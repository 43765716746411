/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { LangURLParams, AccountMigrationURLParams } from './types';

const buildRoute = (...routes: string[]): string => routes.join('/');
export const ROOT_PREFIX = '/';

/**
 * In those routes we are using an optional url param instead of required one.
 * It is done to prevent Not Found reaction on urls without specified lang.
 * In case of localization, user can open urls directly without specified lang.
 */

/**
 * Route: /:lang
 */
export const LangRoot = {
  route: `${ROOT_PREFIX}:lang`,
  url: ({ lang }: LangURLParams): string => buildRoute('', lang)
};

/**
 * Route: /:lang?/
 */
export const Home = {
  route: buildRoute(LangRoot.route, ''),
  url: ({ lang }: LangURLParams): string => buildRoute(LangRoot.url({ lang }), '')
};

/**
 * Route: /:lang/game
 */
export const GameAccounts = {
  route: buildRoute(LangRoot.route, 'game'),
  url: ({ lang }: LangURLParams): string => buildRoute(LangRoot.url({ lang }), 'game')
};

/**
 * Route: /:lang/selectAccount
 */
export const SelectGameAccount = {
  route: buildRoute(LangRoot.route, 'selectAccount'),
  url: ({ lang }: LangURLParams): string => buildRoute(LangRoot.url({ lang }), 'selectAccount')
};

// region Auth Routers
/**
 * Route: /:lang/game/oauth2
 */
export const Auth = {
  route: buildRoute(GameAccounts.route, 'oauth2'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'oauth2')
};

/**
 * Route: /:lang/game/oauth2/code
 */
export const AuthHandler = {
  route: buildRoute(Auth.route, 'code'),
  url: ({ lang }: LangURLParams): string => buildRoute(Auth.url({ lang }), 'code')
};

/**
 * Route: /:lang/game/oauth2/logout
 */
export const LogoutProcessHandler = {
  route: buildRoute(Auth.route, 'logout'),
  url: ({ lang }: LangURLParams): string => buildRoute(Auth.url({ lang }), 'logout')
};

/**
 * Route: /:lang/game/oauth2/logout/complete
 */
export const LogoutCompleteHandler = {
  route: buildRoute(LogoutProcessHandler.route, 'complete'),
  url: ({ lang }: LangURLParams): string => buildRoute(LogoutProcessHandler.url({ lang }), 'complete')
};
// end region

/**
 * Route: /error
 */
export const Error = {
  route: '/error',
  url: (): string => '/error'
};

/**
 * Route: /:lang/game/common/error
 */
export const CommonError = {
  route: buildRoute(GameAccounts.route, 'common', 'error'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'common', 'error')
};

// region RS account migration
/**
 * Route: /:lang/game/migration/:migrationId
 */
export const AccountMigration = {
  route: buildRoute(GameAccounts.route, 'migration', ':migrationId'),
  url: ({ lang, migrationId }: AccountMigrationURLParams): string =>
    buildRoute(GameAccounts.url({ lang }), 'migration', migrationId)
};
// end region

/**
 * Route: /:lang/game/unable-import
 */
export const UnableImport = {
  route: buildRoute(GameAccounts.route, 'unable-import'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'unable-import')
};

// region newcomers pages
/**
 * Route: /:lang/game/newcomers
 */
export const Newcomers = {
  route: buildRoute(GameAccounts.route, 'newcomers'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'newcomers')
};

/**
 * Route: /:lang/game/begin-your-adventure
 */
export const BeginYourAdventure = {
  route: buildRoute(GameAccounts.route, 'begin-your-adventure'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'begin-your-adventure')
};
// end region

// region RS upgrade prompt
/**
 * Route: /:lang/game/upgrade
 */
export const Upgrade = {
  route: buildRoute(GameAccounts.route, 'upgrade'),
  url: ({ lang }: LangURLParams): string => buildRoute(GameAccounts.url({ lang }), 'upgrade')
};
// end region
