/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { injectable, inject } from 'inversify';
import { action, observable, makeObservable, computed } from 'mobx';
import { Loader, Localisation, Snackbars, Auth } from '@stores/domains';
import { withAuth } from '@stores/domains/Auth/decorators';
import { loadable } from '@stores/domains/Loader/decorators';
import { notify } from '@stores/domains/Snackbars/decorators';
import { TYPES } from '@stores/types';
import { ErrorCodeMessages } from '@utils/translations';
import { LoaderName, UserVerificationMethod } from './constants';
import { Requests } from './Requests';
import {
  UserState,
  UserEmailStatus,
  EmailVerificationSubmitData,
  CodeVerificationState,
  UserTypeInformation,
  NewcomerAction,
  CurrentUser
} from './types';

@injectable()
export class User {
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Localisation) localisation: Localisation;
  @inject(TYPES.Snackbars) snackbars: Snackbars;
  @inject(TYPES.Auth) auth: Auth;

  userState: UserState = null;
  userTypeInformation: UserTypeInformation | null = null;

  constructor() {
    makeObservable(this, {
      userState: observable,
      userTypeInformation: observable,
      isReceiveMarketingEmailsConsented: computed,
      setUserState: action,
      setUserTypeInformation: action
    });
  }

  setUserState(userState: UserState): void {
    this.userState = userState;
  }

  setUserTypeInformation(userTypeInformation: UserTypeInformation): void {
    this.userTypeInformation = userTypeInformation;
  }

  get isReceiveMarketingEmailsConsented(): boolean {
    return !!this.userState?.marketingPreferences.receiveEmails;
  }

  get isThirdPartyConsented(): boolean {
    return !!this.userState?.marketingPreferences.thirdPartyConsent;
  }

  @withAuth
  async current(): Promise<void> {
    const [profileData, marketingPreferences] = await Promise.all([
      Requests.current(),
      Requests.currentMarketingPreferences()
    ]);
    this.setUserState({ profileData, marketingPreferences });
  }

  updateUserMarketingPreferences(receiveEmails: boolean, thirdPartyConsent: boolean): void {
    this.setUserState({
      profileData: this.userState?.profileData as CurrentUser,
      marketingPreferences: { receiveEmails, thirdPartyConsent }
    });
  }

  @withAuth
  async getCurrentUserTypeInformation(): Promise<UserTypeInformation> {
    const userTypeInformation = await Requests.currentType();

    this.setUserTypeInformation(userTypeInformation);

    return userTypeInformation;
  }

  @withAuth
  @loadable(LoaderName.StartVerification)
  @notify(null, ErrorCodeMessages.CommonError)
  async startVerification(method: UserVerificationMethod): Promise<CodeVerificationState> {
    return await Requests.startVerification(method);
  }

  @withAuth
  @loadable(LoaderName.ProceedVerification)
  async proceedVerification(
    id: string,
    method: UserVerificationMethod,
    data: EmailVerificationSubmitData
  ): Promise<CodeVerificationState> {
    return await Requests.proceedVerification(id, method, data);
  }

  @withAuth
  @loadable(LoaderName.GetEmailStatus)
  @notify()
  async getEmailStatus(): Promise<UserEmailStatus> {
    return await Requests.getEmailStatus();
  }

  @withAuth
  async getNewcomerAction(): Promise<NewcomerAction> {
    return Requests.getNewcomerAction();
  }
}
