/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { parseBool } from '@utils/helpers';

export const API_URL = window._env_?.API_URL;
export const LOGIN_PORTAL_WEBSITE_BASE_URL = window._env_?.LOGIN_PORTAL_WEBSITE_BASE_URL || '';
export const PLAYER_PORTAL_WEBSITE_BASE_URL = window._env_?.PLAYER_PORTAL_WEBSITE_BASE_URL || '';
export const ACCOUNT_MANAGEMENT_WEBSITE_URL = window._env_?.ACCOUNT_MANAGEMENT_WEBSITE_URL || '';
export const RUNESCAPE_ACCOUNT_SETTINGS_LINK = window._env_?.RUNESCAPE_ACCOUNT_SETTINGS_LINK || '';
export const WEBLOGIN_IMPORT_LINK = window._env_?.WEBLOGIN_IMPORT_LINK || '';
export const JAGEX_LAUNCHER_LINK = window._env_?.JAGEX_LAUNCHER_LINK || '';
export const JAGEX_ACCOUNTS_INFO_LINK = window._env_?.JAGEX_ACCOUNTS_INFO_LINK || '';
export const RUNESCAPE_SUPPORT_LINK = window._env_?.RUNESCAPE_SUPPORT_LINK || '';
export const PAYMENT_WEBSITE_URL = window._env_?.PAYMENT_WEBSITE_URL || '';
export const COOKIES_CONFIG = {
  SECURE_COOKIE: parseBool(window._env_?.SECURE_COOKIE as any),
  COOKIE_DOMAIN: window._env_?.COOKIE_DOMAIN,
  ANALYTICS_MEASUREMENT_ID: window._env_?.ANALYTICS_MEASUREMENT_ID,
  ANALYTICS_COOKIE_PREFIX: window._env_?.ANALYTICS_COOKIE_PREFIX,
  COOKIEBOT_ID: window._env_?.COOKIEBOT_ID
};
