/*
 * Copyright 1999-2025 Jagex Ltd.
 */
export enum AccountMigrationState {
  Init = 'INIT',
  AwaitingToken = 'AWAITING_TOKEN',
  Invalidated = 'INVALIDATED',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type AccountMigrationData = {
  migrationId: string;
  state: AccountMigrationState;
  data: {
    characterId: string;
    displayName: string;
    receiveMarketingEmailsConsented: boolean;
    attributes?: {
      requireWebSession?: boolean;
    };
  };
};

export type StartAccountUpgradeResponse = {
  migrationLink: string;
};

export type StartAccountMigrationData = {
  characterId: string;
};
export type AutoAccountMigrationData = StartAccountMigrationData;

export type FinishAccountMigrationFlowRequestData = {
  receiveEmails: boolean;
};

export enum MigrationCandidateView {
  Hidden = 'hidden',
  Obscured = 'obscured',
  Visible = 'visible'
}
export interface MigrationCandidate {
  characterId: string;
  userHash: string;
  eligibleForMigration: boolean;
  displayName?: string;
}
export type HiddenMigrationCandidate = Omit<MigrationCandidate, 'userHash' | 'displayName'>;
export type ObscuredMigrationCandidate = Omit<MigrationCandidate, 'userHash'>;
export type EligibleMigrationCandidate = HiddenMigrationCandidate | ObscuredMigrationCandidate | MigrationCandidate;
