/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import React, { FunctionComponent, Fragment } from 'react';
import { Text } from 'theme-ui';
import { ContentWrapper, CenteredContainer } from '@components/layouts';
import { PageHelmet } from '@components/meta';
import { PageDocumentTitle } from '@utils/translations';

export const ErrorMessage = 'We are currently experiencing technical difficulties, please try again later.';

type Props = {
  standalone?: boolean;
};

export const GeneralErrorPage: FunctionComponent<Props> = ({ standalone = false }) => {
  const WrapComponents = standalone ? CenteredContainer : Fragment;

  return (
    <WrapComponents>
      <PageHelmet title={PageDocumentTitle.GeneralErrorPage} />
      <ContentWrapper>
        <Text as="p" variant="text.p" sx={{ textAlign: 'center' }}>
          {ErrorMessage}
        </Text>
      </ContentWrapper>
    </WrapComponents>
  );
};
