/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { History } from 'history';
import { injectable, inject } from 'inversify';
import { Routes } from '@common/routes';
import { GenericObject } from '@common/types';
import { Auth, Loader, Localisation } from '@stores/domains';
import { LoaderState } from '@stores/domains/Loader/types';
import { TYPES } from '@stores/types';
import { LoaderName } from './constants';

@injectable()
export class BeginYourAdventurePage {
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Auth) auth: Auth;
  @inject(TYPES.Localisation) localisation: Localisation;

  async init(params: GenericObject, history: History): Promise<void> {
    this.loader.setLoaderState(LoaderName.PageInit, LoaderState.Pending);

    try {
      await this.auth.checkAuthStatus();
    } catch (err) {
      history.push(Routes.CommonError.url({ lang: this.localisation.locale as string }));

      return;
    }

    if (!this.auth.isAuthenticated) {
      this.loader.setLoaderState(LoaderName.PageInit, LoaderState.Finished);

      return;
    }

    history.push(`${Routes.GameAccounts.url({ lang: this.localisation.locale as string })}?modal=create`);
  }
}
