/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import {
  AccountMigrationData,
  FinishAccountMigrationFlowRequestData,
  EligibleMigrationCandidate,
  StartAccountMigrationData,
  AutoAccountMigrationData,
  StartAccountUpgradeResponse
} from './types';

export const Requests = {
  getAccountMigrationData: (migrationId: string, config?: AxiosRequestConfig): Promise<AccountMigrationData> => {
    return extractResponse<AccountMigrationData>(api.get(`/migrations/${migrationId}`, config));
  },
  finishAccountMigrationFlow: (
    migrationId: string,
    body: FinishAccountMigrationFlowRequestData,
    config?: AxiosRequestConfig
  ): Promise<AccountMigrationData> => {
    return extractResponse<AccountMigrationData>(api.post(`/migrations/${migrationId}/states`, body, config));
  },
  getEligibleCandidates: (config?: AxiosRequestConfig): Promise<EligibleMigrationCandidate[]> => {
    return extractResponse<EligibleMigrationCandidate[]>(api.get('/migrations/candidates/eligible', config));
  },
  startAccountMigration: (
    data: StartAccountMigrationData,
    config?: AxiosRequestConfig
  ): Promise<AccountMigrationData> => {
    return extractResponse<AccountMigrationData>(api.post('/migrations', data, config));
  },
  doSimpleAccountMigration: (
    data: AutoAccountMigrationData,
    config?: AxiosRequestConfig
  ): Promise<AccountMigrationData> => {
    return extractResponse<AccountMigrationData>(api.post('/migrations/simple', data, config));
  },
  startAccountUpgrade: (config?: AxiosRequestConfig): Promise<StartAccountUpgradeResponse> => {
    return extractResponse<StartAccountUpgradeResponse>(api.post('/migrations/upgrade', undefined, config));
  }
};
