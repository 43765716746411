/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { createIdGenerator } from '@utils/misc';
import { Snackbar, ActiveSnackbar, SnackbarMessageType, SnackbarType } from './types';

const getId = createIdGenerator();
export const DEFAULT_SNACKBAR_DURATION = 4000;
export const SNACKBAR_TRANSITION_DURATION = 250;

@injectable()
export class Snackbars {
  queue: Snackbar[] = [];
  active: ActiveSnackbar = {
    item: null,
    open: false
  };

  constructor() {
    makeObservable(this, {
      queue: observable,
      active: observable,
      enqueue: action,
      hideCurrentSnackbar: action,
      showNextSnackbar: action,
      processQueue: action
    });
  }

  enqueue = (message: SnackbarMessageType, level: SnackbarType, duration = DEFAULT_SNACKBAR_DURATION): void => {
    this.queue.push({ message, level, duration });

    if (this.active.open) {
      this.hideCurrentSnackbar();
    }

    this.processQueue();
  };

  hideCurrentSnackbar = (): void => {
    this.active.open = false;
  };

  showNextSnackbar = (): void => {
    this.processQueue();
  };

  processQueue = (): void => {
    if (this.queue.length > 0) {
      setTimeout(() => {
        runInAction(
          () =>
            (this.active = {
              item: this.getNextSnackbar(),
              open: true,
              id: getId().toString()
            })
        );
      }, SNACKBAR_TRANSITION_DURATION);
    }

    // Remove leftovers
    if (!this.active.open && this.active.item) {
      this.active = {
        item: null,
        open: false
      };
    }
  };

  getNextSnackbar = (): Snackbar | null => {
    return this.queue.shift() || null;
  };

  enqueueDefaultSuccess = (message: string): void => {
    this.enqueue(message, 'success');
  };

  enqueueDefaultError = (message: string): void => {
    this.enqueue(message, 'error');
  };
}
