/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { GENERIC_RS_AVATAR_URL, DEFAULT_SHORT_POLLING_RETRY_TIME } from '@common/constants';
import {
  LANGUAGE_CODES_MAP,
  LANGUAGE_PLACEHOLDER,
  ACCOUNT_HINT_PLACEHOLDER,
  LanguageCodeType,
  LanguagePrefixesMap
} from '@utils/constants';

/**
 * ID generator factory. Creates a function that increments it's stored ID on each call.
 *
 * @param fromRange - starting range
 */
export const createIdGenerator = (fromRange = 0): (() => number) => {
  let number = fromRange;
  return () => {
    const old = number;
    number += 1;
    return old;
  };
};

export const updateTemplateWithLang = (
  url: string,
  lang: string,
  type: LanguageCodeType,
  map?: LANGUAGE_CODES_MAP
): string => {
  const langPrefix = LanguagePrefixesMap[type];
  const languageToUse = map ? map[lang] : lang;
  const replacement = langPrefix ? `${langPrefix}${languageToUse}` : languageToUse;

  if (url.includes(encodeURIComponent(LANGUAGE_PLACEHOLDER))) {
    return url.replace(encodeURIComponent(LANGUAGE_PLACEHOLDER), encodeURIComponent(replacement));
  }

  if (url.includes(LANGUAGE_PLACEHOLDER)) {
    return url.replace(LANGUAGE_PLACEHOLDER, replacement);
  }

  return url;
};

export const updateTemplateWithAccountHint = (url: string, accountHint: string): string => {
  if (url.includes(ACCOUNT_HINT_PLACEHOLDER)) {
    return url.replace(ACCOUNT_HINT_PLACEHOLDER, accountHint);
  }

  return url;
};

export const makeRSCharacterAvatarLink = (displayName?: string): string => {
  if (displayName) {
    return `https://secure.runescape.com/m=avatar-rs/${displayName}/chat.png`;
  }

  return GENERIC_RS_AVATAR_URL;
};

/**
 * Simple short polling.
 * Allows to execute provided function until validation is passed.
 *
 * @param fn - original function for execution
 * @param args - fn arguments
 * @param validate - validation funtion which checks response
 * @param interval - time before fn will be executed again
 *
 * @returns promise for awaiting in main flow
 */
export const shortPoll = <T>(
  fn: (...args: any) => Promise<T>,
  validate: (...args: any) => boolean,
  args: any[] = [],
  interval = DEFAULT_SHORT_POLLING_RETRY_TIME
): Promise<T> => {
  const executePoll = async (resolve, reject) => {
    try {
      const result = await fn(...args);

      if (validate(result)) {
        return resolve(result);
      }

      setTimeout(executePoll, interval, resolve, reject);
    } catch (e) {
      return reject(e);
    }
  };

  return new Promise(executePoll);
};
