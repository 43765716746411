/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { theme } from '@jagex/design_jagex';
import { ContentWrapper } from '@components/layouts';

export const CardContainer = styled(ContentWrapper)`
  justify-content: flex-start;
  padding: ${theme.space[5]}px ${theme.space[6]}px;
`;
