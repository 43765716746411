/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import Cookies from 'js-cookie';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CookieName } from '@common/constants';
import { COOKIES_CONFIG } from '@common/envs';
import { useStore } from '@stores/useStore';
import { getGoogleAnalyticsScript, defaultGoogleConsent } from '@utils/analytics';
import { parseBool } from '@utils/helpers';
import { useQuery } from '@utils/hooks';
import Favicon from 'static/favicon/favicon.svg';

type GeneralHelmetProps = {
  url?: string;
};

export const GeneralHelmet: FunctionComponent<GeneralHelmetProps> = () => {
  const { t } = useTranslation();
  const { COOKIE_DOMAIN, ANALYTICS_MEASUREMENT_ID, ANALYTICS_COOKIE_PREFIX, COOKIEBOT_ID } = COOKIES_CONFIG;
  const { user } = useStore();
  const { skipCookieDeclaration } = useQuery();

  const shouldSkipCookieDeclaration =
    (typeof skipCookieDeclaration === 'string' && parseBool(skipCookieDeclaration)) ||
    parseBool(Cookies.get(CookieName.SkipCookieDeclaration));

  return (
    <Helmet>
      {/* Title and standard meta information */}
      <meta charSet="UTF-8" />
      <title>{t('gameAccounts:meta:pageTitle')}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="Jagex" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="telephone=no" />
      <script data-cookieconsent="ignore">{defaultGoogleConsent}</script>
      {!shouldSkipCookieDeclaration && (
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={COOKIEBOT_ID}
          data-blockingmode="auto"
          type="text/javascript"
        />
      )}
      <script
        src={`https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_MEASUREMENT_ID}`}
        data-cookieconsent="ignore"
        async
      />
      <script data-cookieconsent="ignore">
        {getGoogleAnalyticsScript(
          ANALYTICS_MEASUREMENT_ID as string,
          COOKIE_DOMAIN as string,
          ANALYTICS_COOKIE_PREFIX as string,
          user.userState?.profileData.id
        )}
      </script>
      {/* Favicon and other icons */}
      {/* TODO: Align with @jagex/design_jagex */}
      <link rel="shortcut icon" type="image/svg+xml" sizes="any" href={Favicon} />
    </Helmet>
  );
};
