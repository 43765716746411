/*
 * Copyright 1999-2025 Jagex Ltd.
 */
import { RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios';
import { RegisterOptions } from 'react-hook-form';

/**
 * Marker interface, describes actual entities
 */
export interface Entity {}

export type ResponseWithHeaders<T> = {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  data: T;
};

export interface HttpRequestNetworkError {
  message: string;
  errorType: 'httpRequest';
}

export interface HttpErrorBody {
  traceId?: string;
  spanId?: string;
  status: number;
  code: string;
  message: string;
  id?: string;
  additionalFields?: Record<string, any>;
}

export interface HttpResponseNetworkError {
  message: string;
  errorType: 'httpResponse';
  data: Partial<HttpErrorBody>;
}

export interface GeneralNetworkError {
  message: string;
  errorType: 'general';
  data?: Partial<HttpErrorBody>;
}

export type NetworkError = HttpRequestNetworkError | HttpResponseNetworkError | GeneralNetworkError;

export type GenericObject = Record<string, any>;

export type QueryParam = string | string[] | undefined;

export interface ValidationSchema {
  [key: string]: RegisterOptions<any>;
}

export enum CharacterStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}
